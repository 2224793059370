import axios from 'axios'

export const locationStoreModule = {
	namespaced: true,
	state: {
		location: null
	},
	mutations: {
		SET_LOCATION(state, payload) {
			state.location = payload.location
		}
	},
	actions: {
		GET_USER_LOCATION_DATA: async (context) => {
			if (context.state.location !== null) {
				return { data: context.state.location, error: undefined }
			}

			try {
				const ipResponse = await axios.get('https://api.ipify.org?format=json')

				if (ipResponse.status !== 200 || !ipResponse.data.ip)
					throw new Error('cant retrive ip from api')

				const res = await axios.get(`https://ipapi.co/${ipResponse.data.ip}/json/`)

				if (res.status !== 200 || !res.data) throw new Error('cant retrive location from api')

				context.commit('SET_LOCATION', { location: res.data })

				return { data: res.data, error: undefined }
			} catch (e) {
				return { data: undefined, error: 'cant retrive location data from api' }
			}
		}
	},
	getters: {
		getLocationIpAddress(state) {
			return state.location ? state.location.ip : null
		}
	}
}
