const verifyPhoneStoreModule = {
	namespaced: true,
	state: {
		country: null,
		contactNo: null,
		isdCode: null,
		userId: null,
		cusip: null,
		isOTPCorrect: false,
		isVerifyPhone: false,
		redirectTo: null,
		IMRWarningMessage: null // if present then after verification show it in profile.
	},
	getters: {
		HAS_VERFIFY_PHONE(state) {
			return state.contactNo && state.cusip && state.userId
		},
		HAS_VERFIFY_PHONE_AND_CORRECT_OTP(state) {
			return state.contactNo && state.isOTPCorrect && state.userId
		},
		GET_PHONE_STATUS(state) {
			return state.isVerifyPhone
		}
	},
	mutations: {
		SET_DATA(state, payload) {
			state.country = payload.country
			state.contactNo = payload.contactNo
			state.isdCode = payload.isdCode
			state.cusip = payload.cusip
			state.userId = payload.userId
			state.redirectTo = payload.redirectTo
			state.IMRWarningMessage = payload?.IMRWarningMessage ?? null
		},
		SET_VERIFY_PHONE_STATUS(state, value) {
			state.isVerifyPhone = value
		},
		SET_IS_OTP_CORRECT(state, value) {
			state.isOTPCorrect = value
		},
		CLEAR_PHONE_CUSIP(state) {
			state.cusip = null
		},
		RESET_PHONE_DATA(state) {
			state.country = null
			state.contactNo = null
			state.isdCode = null
			state.cusip = null
			state.userId = null
		},
		RESET_USER_AND_OTP(state) {
			state.country = null
			state.contactNo = null
			state.isdCode = null
			state.userId = null
			state.isOTPCorrect = false
			state.isVerifyPhone = false
		},
		RESET_ALL(state) {
			state.country = null
			state.contactNo = null
			state.isdCode = null
			state.cusip = null
			state.userId = null
			state.isOTPCorrect = false
			state.isVerifyPhone = false
			state.redirectTo = null
			state.IMRWarningMessage = null
		}
	}
}

export default verifyPhoneStoreModule
