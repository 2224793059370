function promiseWrapper(dbRequest) {
	return new Promise((resolve, reject) => {
		dbRequest.oncomplete = dbRequest.onsuccess = () => resolve(dbRequest.result)
		dbRequest.onabort = dbRequest.onerror = () => reject(dbRequest.error)
	})
}

let db = undefined

function getDatabase() {
	if (db) return db

	db = (function (dbName, storeName) {
		const dbOpenRequest = indexedDB.open(dbName)
		dbOpenRequest.onupgradeneeded = () => dbOpenRequest.result.createObjectStore(storeName)
		const dbPromiseWrapper = promiseWrapper(dbOpenRequest)
		return (action, callback) =>
			dbPromiseWrapper.then((db) =>
				callback(db.transaction(storeName, action).objectStore(storeName))
			)
	})('doctor-diary-database', 'doctor-diary-store')

	return db
}

export function getItem(key, dbInstance = getDatabase()) {
	return dbInstance('readonly', (transaction) => promiseWrapper(transaction.get(key)))
}

export function setItem(key, value, dbInstance = getDatabase()) {
	return dbInstance('readwrite', (transaction) => {
		transaction.put(value, key)
		return promiseWrapper(transaction)
	})
}

export function deleteItem(key, dbInstance = getDatabase()) {
	return dbInstance('readwrite', (transaction) => {
		transaction.delete(key)
		return promiseWrapper(transaction)
	})
}
