import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#303F9F',
				secondary: '#FFFFFF',
				grey: '#99A6B7',
				success: '#24C9A1',
				successLight: '#c4f3e8',
				danger: '#ED6161',
				dangerLight: '#ffd1ce',
				info: '#2196f3'
			}
		},
		options: {
			customProperties: true
		}
	}
})

export default vuetify
