<template>
	<v-app id="inspire" class="bg-app-background">
		<the-sidebar v-if="isNotFullLayout" />
		<!--  -->
		<v-app-bar
			v-if="isNotFullLayout"
			class="d-block d-lg-none d-md-none white"
			elevation="0"
			style="flex: none"
		>
			<v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" @click="handleAppBarIconClick" />
		</v-app-bar>

		<v-main>
			<the-header v-if="isNotFullLayout" />

			<router-view />
		</v-main>
	</v-app>
</template>

<script>
const TheHeader = () => import('@/layout/TheHeader.vue')
const TheSidebar = () => import('@/layout/TheSidebar.vue')
const isLoggedIn =
	localStorage.getItem('isLogin') == true || localStorage.getItem('isLogin') == 'true'
import { AppVersion } from './deviceDetails'

export default {
	name: 'TheApp',
	components: { TheHeader, TheSidebar },
	metaInfo: {
		title: '',
		titleTemplate: '%s | Doctor Diary'
	},
	data() {
		return {
			currentAppVersion: AppVersion
		}
	},
	created() {
		// If app version changed then automatically logout will happen.
		if (this.appVersion) {
			if (this.appVersion != this.currentAppVersion && isLoggedIn) {
				this.logout()
			} else if (this.appVersion != this.currentAppVersion && !isLoggedIn) {
				this.$store.commit('setAppVersion', this.currentAppVersion)
			}
		} else if (!this.appVersion && isLoggedIn) {
			this.logout()
		} else {
			this.$store.commit('setAppVersion', this.currentAppVersion)
		}
	},
	computed: {
		isNotFullLayout() {
			if (!this.$route.name) return false

			return ![
				'Login',
				'Register',
				'ResetPassword',
				'CreatePassword',
				'VerifyOtp',
				'RegisterVerifyOtp',
				'VerifyPhoneNumber',
				'VerifyOtpForPhone',
				'The404Page'
			].includes(this.$route.name)
		},
		appVersion() {
			return this.$store.getters['getAppVersion']
		}
	},
	methods: {
		handleAppBarIconClick() {
			// toggle drawer state means here open
			this.$store.dispatch('TOGGLE_DRAWER')
			// in small screen show it in full view not in mini state
			this.$store.dispatch('TOGGLE_MINI', false)
		},
		logout() {
			this.$store.commit('setUserBlank')
			this.$store.commit('setPrescriptionList', [])
			this.$store.commit('setProfileImage', null)
			this.$store.commit('CLOSE_DRAWER')
			localStorage.removeItem('isLogin')

			this.$router.push('/')
			this.$store.commit('setAppVersion', this.currentAppVersion)
		}
	}
}
</script>

<style scoped>
.bg-app-background {
	background-color: var(--background) !important;
}
</style>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}
.theme-bg {
	background-color: #303f9f !important;
}
.bg {
	background-color: #f2f6fe !important;
}

.breadcrumb a {
	color: white !important;
	text-decoration: none;
}

.relative {
	position: relative;
}

.sticky-top-card-header {
	position: sticky;
	top: 0;
	z-index: 5;
	background-color: #fff;
	border-bottom: 1px solid #0000001f;
}

.sticky-bottom-card-actions {
	position: sticky;
	bottom: 0;
	z-index: 5;
	background-color: #fff;
	border-top: 1px solid #0000001f;
	padding: 16px !important;
	justify-content: flex-end;
}

/* hide input number side buttons */
.no-arrows input[type='number']::-webkit-outer-spin-button,
.no-arrows input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}
.no-arrows input[type='number'],
.no-arrows input[type='number'] {
	-moz-appearance: textfield;
}
.ssoBtn {
	width: 100% !important;
}

.separator__line {
	display: flex;
	justify-content: center;
	position: relative;
	isolation: isolate;
}

.separator__line > * {
	background: white;
	color: var(--v-secondary-darken2);
}

.separator__line::after {
	content: '';
	width: 100%;
	height: 1px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border-top: 1px solid var(--v-secondary-darken2);
	z-index: -1;
}
</style>
