import { ref } from 'vue'
import { getUnReadNotificationCount } from '@/modules/notifications/services/Notification'
import { Success } from '@/statusCodes.js'

export const count = ref(0)

export async function fetchUnreadNotificationCount() {
	try {
		const { data, error } = await getUnReadNotificationCount()

		if (error) throw new Error(error)

		const { Message, StatusCode, Data } = data

		if (StatusCode !== Success) throw new Error(Message)

		count.value = Data
	} catch (e) {
		console.log(e)
	}
}
