const verifyUserStoreModule = {
	namespaced: true,
	state: {
		userName: null,
		isdCode: null,
		contactNo: null,
		cusip: null,
		userId: null,
		isOTPCorrect: false
	},
	getters: {
		HAS_VERFIFY_USER(state) {
			return state.userName && state.cusip && state.userId
		},
		HAS_VERFIFY_USER_AND_CORRECT_OTP(state) {
			return state.userName && state.isOTPCorrect && state.userId
		}
	},
	mutations: {
		SET_USER(state, payload) {
			state.userName = payload.userName
			state.cusip = payload.cusip
			state.userId = payload.userId

			state.isdCode = payload?.isdCode ?? null
			state.contactNo = payload?.contactNo ?? null
		},
		SET_IS_OTP_CORRECT(state, value) {
			state.isOTPCorrect = value
		},
		CLEAR_USER_CUSIP(state) {
			state.cusip = null
		},
		RESET_USER_DATA(state) {
			state.userName = null
			state.cusip = null
			state.userId = null
			state.isdCode = null
			state.contactNo = null
		},
		RESET_USER_AND_OTP(state) {
			state.userName = null
			state.isdCode = null
			state.contactNo = null
			state.userId = null
			state.isOTPCorrect = false
		},
		RESET_ALL(state) {
			state.userName = null
			state.cusip = null
			state.userId = null
			state.isOTPCorrect = false
			state.isdCode = false
			state.contactNo = false
		}
	}
}

export default verifyUserStoreModule
