import Api from '@/services/Api'

export async function ExceptionLog(body) {
	try {
		const { data } = await Api().post('ExceptionLog/LogError', body)
		return { data, error: undefined }
	} catch (e) {
		return { data: undefined, error: e }
	}
}
