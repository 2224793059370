import Api from '@/services/Api'

export async function getNotificationsList(params) {
	try {
		const { data } = await Api().get('PushNotification/GetDoctorPushNotificationList', {
			params
		})
		return { data, error: undefined }
	} catch (e) {
		return { data: undefined, error: e }
	}
}

export async function getNotificationConfig() {
	try {
		const { data } = await Api().get(`Config/GetConfig`)
		return { data, error: undefined }
	} catch (e) {
		return { data: undefined, error: e }
	}
}

export async function getUnReadNotificationCount() {
	try {
		const { data } = await Api().get('PushNotification/GetUnreadNotificationCount')
		return { data, error: undefined }
	} catch (e) {
		return { data: undefined, error: e }
	}
}

/**
 * Save notification config
 *
 * @param {{templateId: number, isEnable: boolean}} params
 * @returns
 */
export async function saveNotificationConfig(params) {
	try {
		const { data } = await Api().post(`Config/SaveConfig`, params)
		return { data, error: undefined }
	} catch (e) {
		return { data: undefined, error: e }
	}
}

/**
 * Mark specific notificaiton as read
 *
 * @param {number} notificationId
 * @returns
 */
export async function readNotification(notificationId) {
	try {
		const { data } = await Api().post(`PushNotification/ReadNotification/${notificationId}`)
		return { data, error: undefined }
	} catch (e) {
		return { data: undefined, error: e }
	}
}
