export const missingInfoStore = {
	namespaced: true,
	state: {
		isOpen: false,
		allInfos: undefined,
		importantInfos: undefined,
		notImportantInfos: undefined,
		backgroundInfos: undefined,
		importantHighestPriorityMissingInfo: undefined,
		currentMissingInfo: undefined,
		currentRequiredMissingFields: {}
	},
	mutations: {
		SET_ALL_INFO(state, payload) {
			state.allInfos = payload.allInfos

			state.importantInfos = payload.allInfos.filter(
				(i) => i.IsForcefully && i.NavigationParam !== 'Background'
			)
			state.notImportantInfos = payload.allInfos
				.filter((i) => !i.IsForcefully && i.NavigationParam !== 'Background')
				.sort((a, b) => a.DisplayPriority - b.DisplayPriority)

			state.backgroundInfos = payload.allInfos.filter((i) => i.NavigationParam === 'Background')

			if (state.importantInfos.length) {
				// Extract highest priority info
				// Note: Lower priority means its important here 0 > 1
				state.importantHighestPriorityMissingInfo = state.importantInfos.reduce(
					(highestPriority, info) => {
						return info.DisplayPriority < highestPriority.DisplayPriority ? info : highestPriority
					},
					state.importantInfos[0]
				)

				state.isOpen = true // open dialog
			} else {
				state.isOpen = false // close dialog
			}
		},
		SET_IS_OPEN(state, open) {
			state.isOpen = open
		},
		SET_CURRENT_MISSING_INFO(state, info) {
			state.currentMissingInfo = info

			state.currentRequiredMissingFields = info
				? info.FrontEndField.split(',').reduce((fields, key) => {
						fields[key] = true
						return fields
				  }, {})
				: {}
		}
	}
}
